import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { userReducer } from './reducers/userReducer';
import { ctReducer } from './reducers/ctReducer';
import { rbReducer } from './reducers/rbReducer';
import { usersReducer } from './reducers/usersReducer';

const persistConfig = {
	key: 'root',
	version: 1,
	storage,
}

const reducer = combineReducers({
	user: userReducer,
	ct: ctReducer,
	rb: rbReducer,
	users: usersReducer
})

const rootReducer = (state, action) => {
	if (action.type === 'USER/LOGOUT') {
	  state = undefined
	  localStorage.removeItem("persist:root")
	}
	return reducer(state, action)
  }

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
	reducer: persistedReducer,
	devTools: process.env.NODE_ENV !== 'production',
})

export default store