import { Controller } from "react-hook-form"
import Select from "react-select"
import styled from "styled-components"
import { customStylesSelect, customTheme } from "./CustomStylesSelect"
import { Calendar, DatePickerWrapper } from "./DatePicker"

const SelectDropdown = ({ options, placeholder, onChange, defaultValue, ...rest }) => {
	return (
		<Select
			{...rest}
			styles={customStylesSelect}
			theme={customTheme}
			options={options}
			placeholder={placeholder}
			onChange={(val) => onChange(val)}
			defaultValue={defaultValue}
			isOptionDisabled={(option) => option.isdisabled}
			isClearable
		></Select>
	)
}

export const LabelSelect = ({ regName, label, options, defaultValue, control, onChange }) => {
	return (
		<LabelSelectStyle>
			<label>{label}</label>
			<Controller
				render={({ field, ref }) => (
					<SelectDropdown
						{...field}
						options={options}
						// onChange={onChange}
						ref={ref}
					/>
				)}
				control={control}
				name={regName}
				defaultValue={defaultValue}
			/>
		</LabelSelectStyle>
	)
}

export const LabelSelectNoController = ({ label, options, defaultValue, onChange }) => {
	return (
		<LabelSelectNoControllerStyles>
			<label>{label}</label>
			<SelectDropdown options={options} onChange={onChange} defaultValue={defaultValue} />
		</LabelSelectNoControllerStyles>
	)
}

export const LabelDate = ({ label, value, handleChange, setValue }) => {
	return (
		<LabelDateStyle>
			<label>{label}</label>
			<DatePickerWrapper
				selected={value}
				calendarContainer={Calendar}
				dateFormat="dd-MM-yyyy"
				onChange={(date) => handleChange(date, setValue)}
			/>
		</LabelDateStyle>
	)
}

const LabelSelectStyle = styled.div`
	max-width: 800px;
	width: 100%;
	height: fit-content;
	label {
		margin-bottom: 4px;
		display: block;
		line-height: 1;
	}
`

const LabelDateStyle = styled.div`
	max-width: 800px;
	width: 100%;
	height: fit-content;
	label {
		margin-bottom: 4px;
		display: block;
		line-height: 1;
	}
`
const LabelSelectNoControllerStyles = styled.div`
	max-width: 800px;
	width: 100%;
	height: fit-content;
	label {
		margin-bottom: 4px;
		display: block;
		line-height: 1;
	}
`
