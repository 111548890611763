import { extendTheme } from "@chakra-ui/react"

export const colors = {
	_blue: "#354f99",
	_white: "#f8f8f8",
	_green: "#47AD6D",
	_red: "#D26060",
	_orange: "#D69636",
	_black: "#000000",
	_grey: "#e0e0e0",
}

export const theme = extendTheme({
	colors: colors,
})
