import { useDispatch, useSelector } from "react-redux"
import { cleanMessage, loginAuth, loginUser, logout, refreshAccessToken, updateProfileData } from "../reducers/userReducer"

export default function useUser() {
	const dispatch = useDispatch()

	const token = useSelector((state) => state.user.token)
	const refreshToken = useSelector((state) => state.user.refreshToken)
	const isAdmin = useSelector((state) => state.user.isAdmin)
	const username = useSelector((state) => state.user.username)
	const firstName = useSelector((state) => state.user.firstName)
	const lastName = useSelector((state) => state.user.lastName)
	const email = useSelector((state) => state.user.email)
	const message = useSelector((state) => state.user.message)
	const loading = useSelector((state) => state.user.loading)

	const signin = async (access, refresh) => {
		dispatch(loginUser(access, refresh))
	}

	const login = async () => {
		return dispatch(loginAuth())
	}

	const signout = async () => {
		dispatch(logout())
	}

	const refreshAccess = async () => {
		dispatch(refreshAccessToken(refreshToken))
	}

	const updateProfile = async (payload) => {
		return dispatch(updateProfileData(payload))
	}

	const cleanToast = async () => {
		dispatch(cleanMessage())
	}

	return {
		signin,
		signout,
		cleanToast,
		refreshAccess,
		updateProfile,
		login,
		token,
		isAdmin,
		refreshToken,
		message,
		loading,
		firstName,
		lastName,
		email,
		username,
	}
}
