import styled from "styled-components"
import DatePicker, { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { maxDate, minDate } from "../utils/values"
import fr from "date-fns/locale/fr"

registerLocale("fr", fr);

export const DatePickerWrapper = styled(({ className, ...props }) => (
    <DatePicker {...props} wrapperClassName={className} minDate={minDate} maxDate={maxDate} locale="fr"/>
))`
    width: 100%;
    input {
        width: 100%;
        background: var(--blue);
        color: var(--white);
        border-radius: 4px;
        height: 50px;
        background-image: url("data:image/svg+xml,%3Csvg width='13' height='8' viewBox='0 0 13 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L6.5 6L12 1' stroke='%23F8F8F8' stroke-width='2' /%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: 98.5%;
        margin-bottom: 0;
    }
`
export const Calendar = styled.div`
    border-radius: 10px;
    box-shadow: 0 6px 12px rgba(27, 37, 86, 0.16);
    overflow: hidden;
`
