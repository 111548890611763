import { format } from "date-fns"
import { fr } from "date-fns/locale"
import styled from "styled-components"
import { asmrsValues, asmrsValues2, bmValues, classValues, fastTrackFive, five, four, no, one, pedValues,
	 rbValues, recommendedLabel, recommendedLimitLabel, recommendedLimitValue, recommendedValue, 
	 sasValues, smsrValues, three, two, typeDemandeValues, yes } from "./values"

const makeDropdownOptions = (values) => {
	const array = []
	values.forEach(val => array.push({label: val, value: val}))
	return array
}

const formatDate = (date) => {
	const dateString = new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(date)
	const formattedDate = dateString.split("/")
	formattedDate.unshift(formattedDate[2])
	formattedDate.pop()
	return formattedDate[0] + "-" + formattedDate[1] + "-" + formattedDate[2]
}

const formatFrenchDate = (date) => {
	
	const splitDate = date.split("-")

	const formattedDate = format(
		new Date(parseInt(splitDate[0]), parseInt(splitDate[1]) - 1, parseInt(splitDate[2])),
		"dd MMMM yyyy",
		{ locale: fr }
	)

	return formattedDate
}

export const checkDateCondition = (date1, date2) => {
	const differenceInTime = date2.getTime() - date1.getTime()
	const differenceInDays = differenceInTime / (1000 * 3600 * 24)

	return differenceInDays
}

export const handleValue = (val, setVal) => {
	if (val) {
		setVal(val.value)
	} else {
		setVal(null)
	}
}

//options
export const yesNoOptions = makeDropdownOptions(pedValues)
export const typeDemandeOptions = makeDropdownOptions(typeDemandeValues)
export const classOptions = makeDropdownOptions(classValues)
export const asmrOptions = makeDropdownOptions(asmrsValues)
export const asmrOptions2 = makeDropdownOptions(asmrsValues2)
export const superAireOptions = makeDropdownOptions(sasValues)
export const bmOptions = makeDropdownOptions(bmValues)
export const rbOptions = makeDropdownOptions(rbValues)
export const smsrOptions = makeDropdownOptions(smsrValues)

export const phiOptions = [
	{ label: recommendedLabel, value: recommendedValue},
	{ label: recommendedLimitLabel, value: recommendedLimitValue}
]

export const asmrsOptionsDisabled = [
	{label: one, value: one},
	{label: two, value: two},
	{label: three, value: three},
	{label: four, value: four, isdisabled: true},
	{label: five, value: five, isdisabled: true},
	{label: fastTrackFive, value: fastTrackFive, isdisabled: true}
]

export const asmrsOptions2Disabled = [
	{label: one, value: one},
	{label: two, value: two},
	{label: three, value: three},
	{label: four, value: four, isdisabled: true},
	{label: five, value: five, isdisabled: true},
]

export const yesNoOptionsDisabled = [
	{label: yes, value: yes, isdisabled: true},
	{label: no, value: no},
]

export const generateCtMessage = (message) => {
	let firstMessage = ""
	let secondMessage = ""

	if(message.Delay) {
		firstMessage = `Le délai prédit est de ${message.Delay} jours. `
	}

	if(message["CT date"]) {
		secondMessage = `La date d'ordre du jour correspondante est le ${formatFrenchDate(message["CT date"])}. `
	}

	const firstDate = message["Adoption date if different ASMR"]
	const secondDate = message["Audition date if different ASMR"]

	if(firstDate && secondDate) {
		secondMessage = secondMessage + `Si l'ASMR obtenue est égale à l'ASMR demandée, la date d'adoption est le ${formatFrenchDate(message["CT date"])}. `
		secondMessage = secondMessage + `Sinon, la date d'adoption correspondante est le ${formatFrenchDate(firstDate)} et la date d'audition planifiée est le 
		${formatFrenchDate(secondDate)}.`
	}

	return (
		<Result>
			<p className="result">{"Résultat :"}</p>
			<ul>
				<li>{firstMessage}</li>
				<li>{secondMessage}</li>
			</ul>
		</Result>
	)
}

export const generateRbMessage = (message) => {
	return (
	
		<Result>
			<p className="result">{"Résultat :"}</p>
		<ul>
			<li>{`Le délai prédit est de ${message} jours.`}</li>
		</ul>
		</Result>
	)
}


const Result = styled.div`
	display: flex;
    flex-direction: column;
    // align-items: left;
	align-self: left;

	.result {
		font-weight: 700;
	}

	li {
		width: 1000px;
		list-style-type: circle;
  		// text-align: left;
	}

`


export const payloadCt = (data) => {
	return ({
		"Super Aire thérapeutique": data.super_aire.value,
		"ATU": data.atu,
		"ASMR demandée": data.asmrs.value,
		"ISP": data.isps.value,
		"Besoin médical": data.bm.value,
		"MK Orphelin": data.orphelin.value,
		"Contribution patient": data.cp.value,
		"Type de demande": data.type_demande.value,
		"Classification": data.clas.value,
		"Dossier en attente concernant la même AT": data.at.value,
		"Date AMM" : formatDate(data.date_amm),
		"Date de dépôt": formatDate(data.date_depot)
	})
}

export const payloadReimbursement = (data) => {
	return ({
		"Super Aire thérapeutique": data.super_aire.value,
		"ATU": data.atu.value,
		"Prioritis HTA Index" : data.phi.value,
		"Liste de remboursement" : data.rb_list,
		"SMR" : data.smrs.value,
		"ASMR demandée": data.asmrs,
		"ASMR obtenue" : data.asmrs_obtenu,
		"ISP": data.isps.value,
		"Besoin médical": data.bm.value,
		"MK Orphelin": data.orphelin.value,
		"Avis CEESP": data.avis_ceesp,
		"Contribution patient": data.cp.value,
		"Type de demande": data.type_demande.value,
		"Classification": data.clas.value,
		"Négociation en cours concernant la même AT" : data.at.value,
		'Négociation de prix' : data.nego_prix,
		"Date AMM" : formatDate(data.date_amm),
		"Date avis": formatDate(data.date_avis)
	})
}