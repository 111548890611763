import ReactApexChart from "react-apexcharts"
import styled from "styled-components"

const Chart = ({ data }) => {
    
    const labels = []
    const reds = []
    const blues = []

    const handleData = (element) => {
        labels.push(element.name)
        if (element.type === "Amplifiant") {
            reds.push(element.value_abs)
            blues.push(0)
        } else {
            blues.push(element.Valeur)
            reds.push(0)
        }
    }

    data.forEach((e) => handleData(e))

    const series = [
        {
            name: "Amplifiant",
            data: reds,
        },
        {
            name: "Atténuant",
            data: blues,
        },
    ]

    const options = {
        chart: {
            type: "bar",
            height: 440,
            stacked: true,
        },
        colors: ["#cf2500", "#295bb0"],
        plotOptions: {
            bar: {
                horizontal: true,
                barHeight: "80%",
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            width: 1,
            colors: ["#fff"],
        },
        grid: {
            xaxis: {
                lines: {
                    show: true,
                },
            },
        },
        yaxis: {
            labels: {
                show: true,
                align: "right",
                minWidth: 160,
                maxWidth: 350,
                height: 60,
                style: {
                    height: 40,
                    fontWeight: 600,
                    cssClass: "apexcharts-yaxis-label",
                },
            },
            // forceNiceScale: true,
            title: {
                // text: 'Age',
            },
        },
        tooltip: {
            shared: false,
            x: {
                formatter: function (val) {
                    return val
                },
            },
            y: {
                formatter: function (val) {
                    return Math.abs(val)
                },
            },
        },
        //   title: {
        // 	text: 'Mauritius population pyramid 2011'
        //   },
        xaxis: {
            categories: labels,

            // title: {
            //   text: 'Percent'
            // },
        },
    }

    return (
        <BarContainer id="chart">
            <ReactApexChart options={options} series={series} type="bar" height={800} />
        </BarContainer>
    )
}

const BarContainer = styled.div`
    width: 100%;
    .apexcharts-yaxis-label {
        background: red;
    }
`

export default Chart
