import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { createUser, fetchUsers, updateUser } from "../reducers/usersReducer"

export default function useUsers() {

    const dispatch = useDispatch()

    const data = useSelector((state) => state.users.data)
	const pagination = useSelector((state) => state.users.pagination)
    const message = useSelector((state) => state.users.message)
    const loading = useSelector((state) => state.users.loading)
	const [modal, setModal] = useState(false)
	
	const fetch = async (pageNumber, pageSize) => {
		dispatch(fetchUsers(pageNumber, pageSize))
	}

	const create = async (data) => {
		return dispatch(createUser(data))
	}

	const update = async (data) => {
		return dispatch(updateUser(data))
	}

    return {
		fetch,
		data,
		pagination,
        message,
        loading,
		modal,
		setModal,
		create,
		update
    }
}