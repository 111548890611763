import { useState } from "react"
import { Controller, useForm } from "react-hook-form"
import styled from "styled-components"
import { ReactComponent as ShowPassword } from "../../assets/show-password.svg"
import { ReactComponent as HidePassword } from "../../assets/hide-password.svg"
import SaveBtn from "../SaveBtn"


const UserForm = ({defaultValues, onSubmit, update, cancel}) => {

	const [passwordShown, setPasswordShown] = useState(false)
    const [repeatPasswordShown, setRepeatPasswordShown] = useState(false)

	const { register, handleSubmit, control } = useForm()

	const toggleVisiblity = (shown, setShown) => {
        setShown(shown ? false : true)
    }

	const checkDefaultCheckedActive = () => {
        if (update) {
            if (defaultValues && defaultValues.is_active) {
                return true
            } else {
                return false
            }
        } else {
            return true
        }
    }

	const checkDefaultCheckedInactive = () => {
        if (defaultValues && !defaultValues.is_active) {
            return true
        } else {
            return false
        }
    }


	return (
		<UserFormStyled onSubmit={handleSubmit(onSubmit)}>
            <div className="personalData-section">
                <div className="sides">
                    <div className="sides-inputGroup">
                        <label className="label-inputs">Username</label>
						<Controller
							render={({ field: { onChange, onBlur, value, ref } }) => (
								<input
									ref={ref}
									value={value}
									onChange={onChange}
								/>
							)}
							control={control}
							name="username"
							defaultValue={defaultValues && defaultValues.username}
							rules={{ required: true }}
						/>
                    </div>
                    <div className="sides-inputGroup">
                        <label className="label-inputs">Password</label>
                        <div className="pas-input-wrapper">
							<Controller
								render={({ field: { onChange, onBlur, value, ref } }) => (
									<input
										className="pas-input"
										ref={ref}
										value={value}
										onChange={onChange}
										type={passwordShown ? "text" : "password"}
									/>
								)}
								control={control}
								name="password"
								rules={!update && {required: true}}
							/>
                            {passwordShown ? (
                                <HidePassword
                                    className="visibility-icon"
                                    onClick={() => toggleVisiblity(passwordShown, setPasswordShown)}
                                />
                            ) : (
                                <ShowPassword
                                    className="visibility-icon"
                                    onClick={() => toggleVisiblity(passwordShown, setPasswordShown)}
                                />
                            )}
                        </div>
                    </div>
                    <div className="sides-inputGroup">
                        <label className="label-inputs">Repeat password</label>
                        <div className="pas-input-wrapper">
							<Controller
								render={({ field: { onChange, onBlur, value, ref } }) => (
									<input
										className="pas-input"
										ref={ref}
										value={value}
										onChange={onChange}
										type={repeatPasswordShown ? "text" : "password"}
									/>
								)}
								control={control}
								name="repeat_password"
								rules={!update && {required: true}}
							/>
                            {repeatPasswordShown ? (
                                <HidePassword
                                    className="visibility-icon"
                                    onClick={() => toggleVisiblity(repeatPasswordShown, setRepeatPasswordShown)}
                                />
                            ) : (
                                <ShowPassword
                                    className="visibility-icon"
                                    onClick={() => toggleVisiblity(repeatPasswordShown, setRepeatPasswordShown)}
                                />
                            )}
                        </div>
                    </div>
                </div>
                <div className="sides">
                    <div className="sides-inputGroup">
                        <label className="label-inputs">First name</label>
						<Controller
							render={({ field: { onChange, onBlur, value, ref } }) => (
								<input
									ref={ref}
									value={value}
									onChange={onChange}
									type="text"
								/>
							)}
							control={control}
							name="first_name"
							defaultValue={defaultValues && defaultValues.first_name}
							rules={{ required: true }}
						/>
                    </div>
                    <div className="sides-inputGroup">
                        <label className="label-inputs">Last name</label>
						<Controller
							render={({ field: { onChange, onBlur, value, ref } }) => (
								<input
									ref={ref}
									value={value}
									onChange={onChange}
									type="text"
								/>
							)}
							control={control}
							name="last_name"
							defaultValue={defaultValues && defaultValues.last_name}
							rules={{ required: true }}
						/>
                    </div>
                    <div className="sides-inputGroup">
                        <label className="label-inputs">Email</label>
                        <Controller
							render={({ field: { onChange, onBlur, value, ref } }) => (
								<input
									ref={ref}
									value={value}
									onChange={onChange}
									type="text"
								/>
							)}
							control={control}
							name="email"
							defaultValue={defaultValues && defaultValues.email}
							rules={{ required: true }}
						/>
                    </div>
                </div>
            </div>
			<div className="radio-buttons-section">
				<div className="radio-button-group">
					<label className="radio-button label-inputs">
						Active

						<input
								value={true}
								type="radio"
								defaultChecked={checkDefaultCheckedActive()}
								{...register("is_active")}
							/>
						<span className="checkmark"></span>
					</label>
					<label className="radio-button label-inputs">
						Inactive
			
							<input
								value={false}
								type="radio"
								defaultChecked={checkDefaultCheckedInactive()}
								{...register("is_active")}
							/>
						
						
						<span className="checkmark"></span>
					</label>
				</div>
			</div>
			<div className="btns-wrapper">
                <div>
                    <SaveBtn />
                    <button className="cancelBtn" type="button" onClick={() => cancel()}>
                        Close
                    </button>
                </div>
                <div></div>
            </div>

		</UserFormStyled>

	)
}

export default UserForm

const UserFormStyled = styled.form`
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    align-items: center;
    justify-content: center;
    min-width: 80%;
    width: 1550px;
    /* height: 860px; */
    text-align: center;
    padding: 15px 20px;
    position: relative;
    .personalData-section {
        justify-content: center;
        column-gap: 80px;
        width: 100%;
        display: flex;
        .sides {
            width: 100%;
            gap: 10px;
            &-inputGroup {
                display: flex;
                margin-bottom: 15px;
                align-items: center;
                gap: 15px;
            }
            .label-inputs {
                display: block;
                width: 120px;
                flex-wrap: nowrap;
                text-align: right;
                color: var(--white);
            }
            .pas-input-wrapper {
                width: 100%;
            }
        }
    }
    .pas-input {
        padding-right: 50px;
    }
    .visibility-icon {
        position: absolute;
        transform: translate(-38px, 8px);
    }
    input {
        width: 100%;
        margin-bottom: 0;
    }
	.btns-wrapper {
        display: grid;
        width: 100%;
        margin-top: 30px;
        /* height:100px; */
        // grid-template-columns: repeat(3, 1fr);
        align-items: center;

        .cashe-btn {
        }
        .cancelBtn {
            margin-left: 20px;

            &:hover {
                color: var(--red);
                transition: 0.3 ease;
            }
        }
    }

	.radio-buttons-section {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		margin-bottom: 20px;
		color: var(--white);
		width: 100%;
		.radio-title {
			display: block;
			margin-bottom: 10px;
		}
		.radio-button-group {
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 0 auto;
			width: 20%;
			:nth-child(2) {
				width: 30%;
			}
			&-with-title {
				width: 25%;
			}
			.radio-button {
				display: block;
				position: relative;
				padding: 0;
				padding-left: 35px;
				:first-child {
					margin-right: 20px;
				}
				cursor: pointer;
				font-size: 16px;
				-webkit-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
			}
			.radio-button input {
				position: absolute;
				opacity: 0;
				cursor: pointer;
				display: none;
			}
			.checkmark {
				position: absolute;
				top: 0;
				left: 0;
				height: 25px;
				width: 25px;
				background-color: var(--blue);
				border: 2px solid var(--white);
				border-radius: 50%;
			}
			.radio-button:hover input ~ .checkmark {
				background-color: var(--blue);
			}
			.radio-button input:checked ~ .checkmark {
				background-color: var(--blue);
			}
			.checkmark:after {
				content: "";
				position: absolute;
				display: none;
			}
			.radio-button input:checked ~ .checkmark:after {
				display: block;
			}

			.radio-button .checkmark:after {
				width: 13px;
				height: 13px;
				border-radius: 50%;
				background: var(--white);
				transform: translate(4px, 4px);
			}
		}




`