import React from "react"
import ReimbursementForm from "../components/forms/ReimbursementForm"
import Navigation from "../components/Navigation"
import { Container } from "./admin/UsersPage"

const ReimbursementPage = () => {
	return (
		<Container>
			<Navigation />
			<ReimbursementForm />
		</Container>
	)
}

export default ReimbursementPage
