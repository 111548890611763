import { createGlobalStyle } from "styled-components"

const GlobalStyles = createGlobalStyle`
    
    *{
        margin: 0;
        padding:0;
        box-sizing: border-box;
        outline: none;
        font-family: 'Rubik', sans-serif;
    }
    :root {
        --blue:#354f99;
        --white:#f8f8f8;
        --green:#47AD6D;;
        --red:#D26060;
        --orange:#D69636;
        --black:#000000;
        --grey:#e0e0e0;
        --graph1:#2c3e50;
        --graph2:#8884d8;
        --graph3:#c0392b;
        --graph4:#847545;
        --graph5:#f07167;
        --graph6:#335c67;
        --graph7:#540b0e;
        --graph8:#588157;
        --graph9:#7cb518;
        
/* RGB MODEL for RGBA transform  */
        --blue_rgb: 53, 79, 153;
        --white_rgb: 248, 248, 248;
        --green_rgb: 23, 156, 94;
        --red_rgb: 210, 96, 96;
        --orange_rgb: 214, 150, 54;
    }
   
    ::-webkit-scrollbar{
        width: 5px;         
    }
    &::-webkit-scrollbar-thumb{
        background-color: var(--blue);
        /* border-radius: 4px; */
           
    }
    &::-webkit-scrollbar-track {
        background: var(--white);
        width:100%; 
    }
    
    input,select{
        height: 40px;
        background: var(--white);
        border-radius: 4px;
        border:none;
        width: 281px;
        margin-bottom: 20px;
        padding:0 10px 0 10px;
        color: var(--blue);
        font-weight: bold;
        font-size: 16px;
            &::placeholder {
            color: var(--blue);
            font-size: 16px;
            font-weight:400;
        }
    } 
    select{
        padding-right:20px;
        cursor: pointer;
        -webkit-appearance: none;
        background-image: url("data:image/svg+xml,%3Csvg width='21' height='13' viewBox='0 0 21 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1.5L10.5 11L20 1.5' stroke='%231E405F' stroke-width='2.5'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: 96%;
        padding-right: 40px;
        option[value=""][disabled] {
            display: none;
        }
    }
    button{
        width: 114px;
        height: 32px;
        border-radius: 4px;
        cursor: pointer;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        border: none;
        &:active {
        transform: translateY(1px);
        box-shadow: none;
      }
    }
    .cancelBtn{
        background: var(--white);
        color: var(--red);
    }
    a{
        text-decoration: none;
    }
`
export default GlobalStyles
