import React from "react";
import styled from "styled-components";
import ReactDom from "react-dom";

const Modal = ({ children }) => {
	return ReactDom.createPortal(
		<Overlay>
			<Container>{children}</Container>
		</Overlay>,
		document.getElementById("modal-portal")
	);
};
const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Container = styled.div`
  background: var(--blue);
  /* padding: 30px; */
  border-radius: 4px;
`;

export default Modal;
