import { useDispatch, useSelector } from "react-redux";
import { calculateRbPrediction, cleanMessageAndGraph, explainRbPrediction } from "../reducers/rbReducer";

export default function useRb() {

	const dispatch = useDispatch();
	const graphData = useSelector((state) => state.rb.graphData);
	const message = useSelector((state) => state.rb.message);
	const loading = useSelector((state) => state.rb.loading);

	const explainRb = async (payload) => {
		return dispatch(explainRbPrediction(payload));
	}

	const predictRb = async (payload) => {
		return dispatch(calculateRbPrediction(payload));
	}

	const clean = async () => {
		return dispatch(cleanMessageAndGraph())
	}

	return {
		graphData,
		message,
		loading,
		explainRb,
		predictRb,
		clean,
	};
}