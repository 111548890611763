import React, { useEffect } from "react";
import UpdateProfileForm from "../components/forms/UpdateProfileForm";
import useUser from "../redux/hooks/useUser";
import generateToast from "../components/ToastGenerator";
import Navigation from "../components/Navigation";
import { Container } from "./admin/UsersPage";

const ProfilePage = () => {

  const hook = useUser()

  useEffect(() => {
    if (hook.message) {
      generateToast(hook.message)
    }
  }, [hook.message]);

  return (
      <Container>
		<Navigation />
        <UpdateProfileForm />
      </Container>
  )
}

export default ProfilePage;
