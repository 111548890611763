import React from "react"
import styled from "styled-components"

const UsersTable = ({ children }) => {
    return (
        <TableStyles>
            <thead>
                <tr>
                    <th>Username</th>
                    <th>First name</th>
                    <th>Last name</th>
                    <th>Email</th>
					<th>Active</th>
                </tr>
            </thead>
            <tbody>{children}</tbody>
        </TableStyles>
    )
}

export default UsersTable

const TableStyles = styled.table`
    min-width: 600px;
    width: 100%;
    height: fit-content;
    border-collapse: collapse;
    tr {
        width: 100%;
    }
    tbody tr {
        &:hover {
            color: var(--white);
            background: var(--blue);
            cursor: pointer;
        }
    }

    th,
    td {
        border: 2px solid var(--blue);
        text-align: center;
        padding: 4px;
        min-width: 70px !important;
    }

    th {
        border-top: 0;
    }
    th:first-child {
        border-left: 0;
    }
    th:last-child {
        border-right: 0;
    }
    tr:first-child td {
        border-top: 0;
    }
    tr td:first-child {
        border-left: 0;
    }
    tr td:last-child {
        border-right: 0;
    }
`
