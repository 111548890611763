import { Flex, Stack } from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import styled from "styled-components"
import useCt from "../../redux/hooks/useCt"
import {
	asmrOptions,
	bmOptions,
	checkDateCondition,
	classOptions,
	generateCtMessage,
	payloadCt,
	superAireOptions,
	typeDemandeOptions,
	yesNoOptions,
} from "../../utils/dropdownData"
import {
	asmrLabel,
	atLabel,
	atuLabel,
	bmLabel,
	calculate,
	classLabel,
	cpLabel,
	dateAmmLabel,
	dateConditionMessage,
	dateDepotLabel,
	defaultDate,
	formHeaderCt,
	ispsLabel,
	orphelinLabel,
	superAireLabel,
	typeDemandeLabel,
	yes,
} from "../../utils/values"
import Chart from "../Chart"
import { LabelDate, LabelSelect, LabelSelectNoController } from "../SelectDropdown"

const CtForm = () => {
	const hook = useCt()

	const { handleSubmit, control, watch } = useForm()

	const [firstDate, setFirstDate] = useState(defaultDate)
	const [secondDate, setSecondDate] = useState(defaultDate)
	const [atuValue, setAtuValue] = useState(yes)
	const [message, setMessage] = useState(null)
	const [graphData, setGraphData] = useState(null)

	useEffect(() => {
		hook.clean()
	}, []) //eslint-disable-line

	useEffect(() => {
		if (firstDate && secondDate) {
			const days = checkDateCondition(firstDate, secondDate)
			if (days > 32 && atuValue === yes) {
				setMessage(dateConditionMessage)
			} else if (message === dateConditionMessage) {
				setMessage(null)
			}
		}
	}, [firstDate, secondDate, atuValue]) //eslint-disable-line

	const handleReset = () => {
		setGraphData(null)
		setMessage(null)
	}

	useEffect(() => {
		const subscription = watch(() => handleReset())
		return () => subscription.unsubscribe()
	}, [watch])

	useEffect(() => {
		if (hook.graphData) {
			setGraphData(hook.graphData)
		} else {
			setGraphData(null)
		}
	}, [hook.graphData])

	useEffect(() => {
		if (hook.message && typeof hook.message === "object") {
			setMessage(hook.message)
		} else if (hook.message) {
			setMessage(hook.message)
		} else {
			setMessage(null)
		}
	}, [hook.message])

	const handleAtu = (val) => {
		if (val) {
			setAtuValue(val.value)
		} else {
			setAtuValue(null)
		}
	}

	const submit = (data) => {
		hook.clean()
		setMessage(null)
		setGraphData(null)

		data["date_amm"] = firstDate
		data["date_depot"] = secondDate
		data["atu"] = atuValue

		hook.explainCt(payloadCt(data))

		hook.predictCt(payloadCt(data))
	}

	const handleDate = (data, setValue) => {
		setGraphData(null)
		setValue(data)
	}

	return (
		<Form onSubmit={handleSubmit(submit)}>
			<h2 className="title">{formHeaderCt}</h2>
			<Flex
				gap={[2, 2, 5]}
				w="100%"
				maxW="1400px"
				px={[2, 5]}
				direction={["column", "column", "row"]}
				sx={{
					".react-datepicker__input-container input": { pl: 2 },
				}}
			>
				<Stack w="100%" spacing={4}>
					<LabelDate label={dateAmmLabel} value={firstDate} setValue={setFirstDate} handleChange={handleDate} />

					<LabelSelect
						regName="type_demande"
						label={typeDemandeLabel}
						options={typeDemandeOptions}
						defaultValue={typeDemandeOptions[0]}
						control={control}
					/>

					<LabelSelect
						regName="clas"
						label={classLabel}
						options={classOptions}
						defaultValue={classOptions[2]}
						control={control}
					/>

					<LabelSelect regName="asmrs" label={asmrLabel} options={asmrOptions} defaultValue={asmrOptions[3]} control={control} />

					<LabelSelect
						regName="super_aire"
						label={superAireLabel}
						options={superAireOptions}
						defaultValue={superAireOptions[1]}
						control={control}
					/>

					<LabelSelectNoController label={atuLabel} options={yesNoOptions} defaultValue={yesNoOptions[0]} onChange={handleAtu} />
				</Stack>
				<Stack w="100%" spacing={4}>
					<LabelDate label={dateDepotLabel} value={secondDate} setValue={setSecondDate} handleChange={handleDate} />
					<LabelSelect
						regName="orphelin"
						label={orphelinLabel}
						options={yesNoOptions}
						defaultValue={yesNoOptions[1]}
						control={control}
					/>

					<LabelSelect regName="cp" label={cpLabel} options={yesNoOptions} defaultValue={yesNoOptions[0]} control={control} />

					<LabelSelect regName="isps" label={ispsLabel} options={yesNoOptions} defaultValue={yesNoOptions[0]} control={control} />

					<LabelSelect regName="bm" label={bmLabel} options={bmOptions} defaultValue={bmOptions[0]} control={control} />

					<LabelSelect regName="at" label={atLabel} options={yesNoOptions} defaultValue={yesNoOptions[0]} control={control} />
				</Stack>
			</Flex>
			<CalculateButton type="submit">{calculate}</CalculateButton>
			{message && generateCtMessage(message)}
			{graphData && <h3 className="title">{"Impact des différents paramètres sur la prédiction effectuée:"}</h3>}
			{graphData && (
				<div className="chart-container">
					<Chart data={graphData} />
				</div>
			)}
		</Form>
	)
}

const Form = styled.form`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	.title {
		margin: 40px 10px;
		font-weight: 700;
	}
	.container {
		width: 80%;
		display: flex;
		grid-gap: 20px;
		margin: 0 10px;
	}
	.left-side,
	.right-side {
		width: 50%;
		display: flex;
		flex-direction: column;
		row-gap: 20px;
	}
	@media (max-width: 1200px) {
		.container {
			width: 90%;
		}
	}
	@media (max-width: 768px) {
		.container {
			flex-direction: column;
			align-items: center;
		}
		.left-side,
		.right-side {
			width: 90%;
		}
	}

	.chart-container {
		width: 80%;
		margin-bottom: 100px;
	}

	.message {
		width: 80%;
	}

	.message {
		width: 80%;
	}
`
const CalculateButton = styled.button`
	background: var(--green);
	color: var(--white);
	font-size: 18px;
	margin: 40px 0;
`

export default CtForm
