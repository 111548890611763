import React, { useState } from "react"
import styled from "styled-components"
import useUsers from "../../../redux/hooks/useUsers"
import { toast } from "react-toastify"
import { ReactComponent as TrueIcon } from "../../../assets/check.svg"
import { ReactComponent as FalseIcon } from "../../../assets/delete.svg"
import UserForm from "../../forms/UserForm"
import AddUpdateModal from "../../../components/AddUpdateModal"
import { defaultPageSize } from "../../../redux/api"

const UserItem = (props) => {
    const hook = useUsers()

    const {
        id,
        username,
        first_name,
        last_name,
        email,
        is_active,
    } = props.item
    const [open, setOpen] = useState(false)

    const handleUpdate = (data) => {


        if (data.password !== data.repeat_password) {
            toast.error("Passwords do not match")
			return
        }else {
			let isActive = false
			if(data.is_active === "true") {
				isActive = true
			}
            const payload = {
                id: id,
                username: data.username,
                password: data.password,
                repeated_password: data.repeat_password,
                first_name: data.first_name,
                last_name: data.last_name,
                email: data.email,
				is_active: isActive
            }
            hook.update(payload).then((res) => {
				if(res !== undefined && res.status === 200) {
					hook.fetch(1, defaultPageSize)
					hook.setModal(false)
				}
			})
        }
    }

    const handleCancel = () => {
        hook.setModal(false)
    }

	const defaultValues = {
        username: username,
        first_name : first_name,
        last_name : last_name,
        email : email,
        is_active : is_active,
	}

    return (
        <>
            <TrStyles>
                <td onClick={() => setOpen(!open)} className={open ? "active" : ""}>
                    {username}
                </td>
                <td onClick={() => setOpen(!open)} className={open ? "active" : ""}>
                    {first_name}
                </td>
                <td onClick={() => setOpen(!open)} className={open ? "active" : ""}>
                    {last_name}
                </td>
                <td onClick={() => setOpen(!open)} className={open ? "active" : ""}>
                    {email}
                </td>
                <td onClick={() => setOpen(!open)} className={open ? "active" : ""}>
                    {is_active ? <TrueIcon /> : <FalseIcon />}
                </td>
              
            </TrStyles>

            {open && (
                <TrOptionsStyled>
                    <td colSpan="5">
                        <EditBtnStyled onClick={() => hook.setModal(true)}>Edit</EditBtnStyled>
                    </td>
                </TrOptionsStyled>
            )}
            {hook.modal && (
                <tr>
                    <TdModalCorectStyles>
					
						<AddUpdateModal>
							<UserForm
								onSubmit={handleUpdate}
								cancel={handleCancel}
								defaultValues={defaultValues}
								update={true}
							/>
						</AddUpdateModal>
				
                    </TdModalCorectStyles>
                </tr>
            )}
        </>
    )
}
const TrStyles = styled.tr`
    .active {
        background: var(--blue);
        color: var(--white);
    }
`
// pay attention to locked values with !important
const TrOptionsStyled = styled.tr`
    width: 100%;
    td {
        text-align: start !important;
        background: var(--blue);
        border-bottom: 2px solid var(--white) !important;
    }
`

const EditBtnStyled = styled.button`
    color: var(--white);
    margin-right: 2%;
    background: var(--orange);
    cursor: pointer;
`

// pay attention to locked values with !important
const TdModalCorectStyles = styled.td`
    padding: 0 !important;
`

export default UserItem
