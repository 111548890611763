import { Icon } from "@chakra-ui/react"

export const BurgerMenu = (props) => (
	<Icon viewBox="0 0 38 38" fill="none" {...props}>
		<path stroke="currentColor" strokeLinecap="round" strokeWidth="3" d="M4.75 11.084h28.5M4.75 19h28.5M4.75 26.916h28.5"></path>
	</Icon>
)

export const Logout = (props) => (
	<Icon viewBox="0 0 24 24" fill="none" {...props}>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M5 3H19C20.1 3 21 3.9 21 5V19C21 20.1 20.1 21 19 21H5C3.89 21 3 20.1 3 19V15H5V19H19V5H5V9H3V5C3 3.9 3.89 3 5 3ZM11.5 17L10.09 15.59L12.67 13H3V11H12.67L10.09 8.41L11.5 7L16.5 12L11.5 17Z"
			fill="black"
			fill-opacity="0.54"
		/>
	</Icon>
)
