import {
	Box,
	Button,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerOverlay,
	Flex,
	Hide,
	IconButton,
	Show,
	Stack,
	Text,
	useDisclosure,
} from "@chakra-ui/react"
import { useRef } from "react"
import { useHistory } from "react-router"
import styled from "styled-components"
import { ReactComponent as Logo } from "../assets/prioritis-logo.svg"
import useUser from "../redux/hooks/useUser"
import { appName, ctTitle, logout, profile, reimbursementTitle, users } from "../utils/values"
import { BurgerMenu, Logout } from "./icons"

const Navigation = ({ formSwitch, active }) => {
	const hook = useUser()
	const history = useHistory()
	const { isOpen, onOpen, onClose } = useDisclosure()
	const btnRef = useRef()

	const path = window.location.href.split("/")

	return (
		<>
			<Flex
				as="nav"
				h="54px"
				w="100%"
				align="center"
				justify="space-between"
				bg="_blue"
				color="_black"
				p="0 20px 0 20px"
				fontSize="15px"
				position={{ base: "sticky", lg: "unset" }}
				top={0}
				zIndex={10}
				sx={{
					".nav-buttons": {
						bg: "_white",
						p: "1px 4px",
						borderRadius: "4px",
						boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
						color: "_black",
						cursor: "pointer",
						_active: {
							boxShadow: "none",
							transform: "translateY(1px)",
							color: "inherit",
						},
					},
				}}
			>
				<Hide below="lg">
					<Flex align="center" gap="30px">
						<Logo />
						<Text color="_white">{appName}</Text>
					</Flex>
					<Flex gap="30px">
						{hook.isAdmin && (
							<Box
								className="nav-buttons"
								style={{ background: path[path.length - 1] === "users" ? "#fbecbe" : "var(--white)" }}
								title={users}
								onClick={(e) => history.push("/admin/users")}
							>
								<p className="nav-title">{users}</p>
							</Box>
						)}

						<Box
							className="nav-buttons"
							style={{ background: path[path.length - 1] === "ct" ? "#fbecbe" : "var(--white)" }}
							title={ctTitle}
							onClick={(e) => history.push("/ct")}
						>
							<p className="nav-title">{ctTitle}</p>
						</Box>

						<Box
							className="nav-buttons"
							title={reimbursementTitle}
							style={{ background: path[path.length - 1] === "reimbursement" ? "#fbecbe" : "var(--white)" }}
							onClick={(e) => history.push("/reimbursement")}
						>
							<p className="nav-title">{reimbursementTitle}</p>
						</Box>
						<Box
							className="nav-buttons"
							title={profile}
							style={{ background: path[path.length - 1] === "profile" ? "#fbecbe" : "var(--white)" }}
							onClick={(e) => history.push("/profile")}
						>
							<p className="nav-title">{profile}</p>
						</Box>
					</Flex>
					<Button
						h="26px"
						minW="160px"
						display="flex"
						alignItems="center"
						// p="4px !important"
						onClick={() => hook.signout()}
						className="nav-buttons"
						title={logout}
						leftIcon={<Logout boxSize={6} />}
						fontSize="15px"
						fontWeight={400}
					>
						{logout}
					</Button>
				</Hide>
				<Show below="lg">
					<Flex w="100%" justify="space-between" zIndex={10}>
						<Logo />
						<IconButton
							aria-label="menu button"
							w="30px"
							bg="_blue"
							variant="unstyled"
							boxShadow="none"
							ref={btnRef}
							colorScheme="teal"
							onClick={onOpen}
							icon={<BurgerMenu boxSize={8} color="_white" />}
						/>
					</Flex>
				</Show>
			</Flex>
			<Drawer isOpen={isOpen} placement="right" onClose={onClose} finalFocusRef={btnRef}>
				<DrawerOverlay />
				<DrawerContent w="100%" bg="_blue" maxW="240px">
					<DrawerCloseButton
						color="_white"
						borderColor="_white"
						border="3px solid"
						boxShadow="none"
						rounded="full"
						icon={<BurgerMenu />}
						sx={{ svg: { boxSize: 3 }, path: { fill: "_white" } }}
					/>

					<DrawerBody
						pt="60px"
						px={5}
						pb={5}
						sx={{
							".nav-buttons": {
								bg: "_white",
								p: "1px 4px",
								borderRadius: "4px",
								boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
								color: "_black",
								cursor: "pointer",
								_active: {
									boxShadow: "none",
									transform: "translateY(1px)",
									color: "inherit",
								},
							},
						}}
					>
						<Flex direction="column" justify="space-between" h="100%" align="flex-end">
							<Stack w="100%" align="flex-end" spacing={6}>
								<Text color="_white" fontWeight={500}>
									{appName}
								</Text>
								{hook.isAdmin && (
									<Box
										className="nav-buttons"
										style={{ background: path[path.length - 1] === "users" ? "#fbecbe" : "var(--white)" }}
										title={users}
										onClick={(e) => history.push("/admin/users")}
									>
										<p className="nav-title">{users}</p>
									</Box>
								)}

								<Box
									className="nav-buttons"
									style={{ background: path[path.length - 1] === "ct" ? "#fbecbe" : "var(--white)" }}
									title={ctTitle}
									onClick={(e) => history.push("/ct")}
								>
									<p className="nav-title">{ctTitle}</p>
								</Box>
								<Box
									className="nav-buttons"
									title={reimbursementTitle}
									style={{ background: path[path.length - 1] === "reimbursement" ? "#fbecbe" : "var(--white)" }}
									onClick={(e) => history.push("/reimbursement")}
								>
									<p className="nav-title">{reimbursementTitle}</p>
								</Box>
							</Stack>
							<Stack spacing={6} align="flex-end">
								<Box
									className="nav-buttons"
									title={profile}
									style={{ background: path[path.length - 1] === "profile" ? "#fbecbe" : "var(--white)" }}
									onClick={(e) => history.push("/profile")}
								>
									<p className="nav-title">{profile}</p>
								</Box>
								<Button
									h="26px"
									minW="160px"
									display="flex"
									alignItems="center"
									// p="4px !important"
									onClick={() => hook.signout()}
									className="nav-buttons"
									title={logout}
									leftIcon={<Logout boxSize={6} />}
									fontSize="15px"
									fontWeight={400}
								>
									{logout}
								</Button>
							</Stack>
						</Flex>
					</DrawerBody>
				</DrawerContent>
			</Drawer>
		</>
	)
}

const Nav = styled.nav`
	.nav-left {
		display: flex;
		align-items: center;
		column-gap: 30px;
		p {
			color: var(--white);
		}
	}
	.nav-center,
	.nav-right {
		display: flex;
		column-gap: 30px;
	}
	.chart-container {
		width: 80%;
		margin-bottom: 100px;
	}
	.nav-buttons {
		display: flex;
		align-items: center;
		background: var(--white);
		height: 26px;
		padding: 1px 4px;
		border-radius: 4px;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		color: var(--black);
		cursor: pointer;
		:visited {
			color: var(--black);
		}
		:active {
			box-shadow: none;
			transform: translateY(1px);
			color: inherit;
		}
	}

	.nav-title {
		color: var(--black);
		:active {
			color: inherit;
		}
	}
`
export default Navigation
