import React from "react";
import styled from "styled-components";

const SaveBtn = () => {
  return <SaveBtnStyles type="submit">Save</SaveBtnStyles>;
};
const SaveBtnStyles = styled.button`
  background: var(--green);
  color: var(--white);
`;
export default SaveBtn;
