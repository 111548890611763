import React from "react";
import { Redirect, Route } from "react-router-dom";
import useUser from "../redux/hooks/useUser";

const AppRoute = ({
  component: Component,
  path,
  isPrivate,
  isAdmin,
  ...rest
}) => {
	
	const hook = useUser()
	const token = hook.token
	const isUserAdmin = hook.isAdmin

	return (
		<Route
			path={path}
			render={(props) => {
				if (isPrivate && !Boolean(token)) {
				return <Redirect to={{ pathname: "/login" }} />;
				} else if ((path === "/" || path === "/login") && token && isUserAdmin) {
				return <Redirect to={{ pathname: "/admin/users" }} />;
				} else if ((path === "/" || path === "/login") && token && !isUserAdmin) {
				return <Redirect to={{ pathname: "/ct" }} />;
				} else if (isAdmin && !Boolean(isUserAdmin)) {
				return <Redirect to={{ pathname: "/404" }} />;
				}else {
				return <Component {...props} />;
				}
			}}
      		{...rest}
    />

	);
};

export default AppRoute;