import React, { useEffect } from "react"
import { toast } from "react-toastify"
import styled from "styled-components"
import { ReactComponent as AddButton } from "../../assets/add-button.svg"
import AddUpdateModal from "../../components/AddUpdateModal"
import UserForm from "../../components/forms/UserForm"
import Navigation from "../../components/Navigation"
import TableFrame from "../../components/tables/frames/TableFrame"
import Pagination from "../../components/tables/Pagination"
import UserItem from "../../components/tables/table-items/UserItem"
import UsersTable from "../../components/tables/UserTable"
import generateToast from "../../components/ToastGenerator"
import { defaultPageSize } from "../../redux/api"
import useUsers from "../../redux/hooks/useUsers"

const UsersPage = () => {
	const hook = useUsers()

	useEffect(() => {
		hook.fetch(1, defaultPageSize)
	}, []) //eslint-disable-line

	useEffect(() => {
		if (hook.message) {
			generateToast(hook.message)
		}
	}, [hook.message])

	const submit = (data) => {
		let isActive = false
		if (data.is_active === "true") {
			isActive = true
		}

		if (data.password !== data.repeat_password) {
			toast.error("Passwords do not match")
		} else {
			const payload = {
				username: data.username,
				first_name: data.first_name,
				last_name: data.last_name,
				email: data.email,
				password: data.password,
				repeated_password: data.repeat_password,
				is_active: isActive,
			}

			hook.create(payload).then((res) => {
				if (res !== undefined && res.status === 200) {
					hook.fetch(1, defaultPageSize)
					hook.setModal(false)
				}
			})
		}
	}

	const handleCancel = () => {
		hook.setModal(false)
	}

	return (
		<Container>
			<Navigation />
			<TableFrame>
				<UsersTable>
					{hook.data.length > 0 &&
						hook.data.map((item) => {
							return <UserItem key={item.id} item={item} />
						})}
				</UsersTable>
			</TableFrame>
			<AddButton className="addBtn" onClick={() => hook.setModal(true)} />
			{hook.pagination && (
				<Pagination
					totalCount={hook.pagination.total_count}
					totalPages={hook.pagination.total_pages}
					currentPage={hook.pagination.current_page}
					pageSize={defaultPageSize}
					fetchPage={hook.fetch}
				/>
			)}
			{hook.modal && (
				<AddUpdateModal>
					<UserForm onSubmit={submit} cancel={handleCancel} />
				</AddUpdateModal>
			)}
		</Container>
	)
}

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	min-height: calc(100vh);
	background: var(--white);

	/* @media (max-width: 1290px) {
        .nav-title {
            display: none;
        }
        .nav-buttons {
            position: relative;
        }
    } */

	.addBtn {
		position: absolute;
		right: 60px;
		bottom: 20px;
		cursor: pointer;
		width: 68px;
		height: 68px;
		@media (max-width: 1024px) {
			right: 20px;
			bottom: 70px;
		}
		@media (max-width: 1400px) {
			width: 72px;
			height: 72px;
			right: 25px;
			bottom: 32px;
		}
	}
`

export default UsersPage
