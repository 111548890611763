//values
export const yes = "Oui"
export const no = "Non"
const ins = "INS"
const eit = "EIT"
const other = "Autre"

//class
const newProduct = "Nouveau produit"
const newEntity = "Nouvelle entité"
const newIndication = "Nouvelle indication"
const newAssociation = "Nouvelle association"

//asmrs 
export const one = "I"
export const two = "II"
export const three = "III"
export const four = "IV"
export const five = "V"
export const fastTrackFive = "Fast-Track V"

//sas 
const oncology = "Oncologie"
const rareSickness = "Maladie rare"
const diagnostic = "Diagnostic"
const cardioVascular = "Cardiovasculaire"
const endocrinology = "Endocrinologie, troubles métaboliques ou nutritionnels"
const dermatology = "Dermatologie"
const rheumatology = "Rhumatologie"
const pulmonology = "Pneumologie"
const neurologyPsych = "Neurologie psychiatrie"
const infectiousDisease = "Maladies infectieuses"
const hepatoGastro = "Hépato-gastroentérologie"
const vaccine = "Vaccin (Immunisation)"

//bm 
const notCovered = "Non couvert"
const partiallyCovered = "Partiellement couvert"
const covered = "Couvert"

//rb 
export const ss = "SS"
export const coll = "COLL"
const retro = "RETRO"
const t2a = "T2A"

//phi
export const recommendedValue = "Recommended"
export const recommendedLimitValue = "Recommended with limitations"
export const recommendedLabel = "Recommandé"
export const recommendedLimitLabel = "Recommandé avec limitations"
// const notRecommended = "Not recommended"

//smsr
const important = "Important"
const moderate = "Modéré"
const low = "Faible"

export const pedValues = [yes, no]
export const typeDemandeValues = [ins, eit]
export const classValues = [newProduct, newEntity, newIndication, newAssociation, other]
export const asmrsValues = [one, two, three, four, five, fastTrackFive]
export const asmrsValues2 = [one, two, three, four, five]
export const sasValues = [oncology, rareSickness, diagnostic, cardioVascular, endocrinology, dermatology, rheumatology, pulmonology,
						  neurologyPsych, infectiousDisease, hepatoGastro, vaccine, other]
export const bmValues = [notCovered, partiallyCovered, covered]

export const rbValues = [ss, coll, retro, t2a]
export const smsrValues = [important, moderate, low]

//dates
export const minDate = new Date(2016, 12, 1)
export const maxDate = new Date(2022, 11, 31)
export const defaultDate = new Date(2020, 12, 1)


//labels
export const dateAmmLabel = "Indiquer la date d'AMM"
export const dateDepotLabel = "Indiquer la date de dépôt"
export const dateAvisLabel = "Indiquer la date d'avis CT"
export const typeDemandeLabel = "Selectionner le type de demande"
export const classLabel = "Sélectionner la classification"
export const asmrLabel = "Sélectionner l'ASMR demandée"
export const atuLabel = "Sélectionner si le produit possède une ATU"
export const orphelinLabel = "Sélectionner si le produit est orphelin"
export const cpLabel = "Sélectionner s'il y a une contribution patient"
export const ispsLabel = "Sélectionner si le produit a un ISP"
export const bmLabel = "Sélectionner si le besoin médical est..."
export const atLabel = "Sélectionner s'il y a un dossier en attente concernant la même aire thérapeutique"
export const superAireLabel = "Sélectionner la super-aire thérapeutique concernée"
export const rbListLabel = "Sélectionner la liste de remboursement"
export const phiLabel = "Sélectionner l'index HTA de Prioritis pour cet avis"
export const smrsLabel = "Sélectionner le SMR obtenu"
export const asmrObtenuLabel = "Sélectionner l'ASMR obtenue"
export const avisLabel = "Sélectionner si le produit a un avis CEESP"
export const negoPrixLabel = "Aura-t-il une négociation de prix?"

export const serverErrorMessage = "Erreur du serveur!"
export const wrongCredentialsMessage = "Aucun compte actif avec les informations d'identification fournies n'a été trouvé!"
export const impossibleMessage = "Impossiple d'avoir un avis CEESP si l'ASMR obtenue n'est pas dans ('I','II', 'III')"
export const dateConditionMessage = "En cas d'ATU, le dépôt doit être fait dans le mois qui suit la date d'AMM. Veuillez modifier la date d'AMM ou la date de dépôt."
export const negoListConditionMessage = "Il faut y avoir une négociation de prix si la liste de remboursement est dans (SS, RETRO, T2A)"


export const ctTitle = "Délai CT"
export const reimbursementTitle = "Délai de remboursement"
export const formHeaderCt = "Prédiction des délais de traitement des dossiers par la Comission de la Transparence"
export const formHeaderRb = "Prédiction des délais d'inscription sur les listes de remboursement"
export const appName = "Délais d'accès au marché"
export const logout = "Se déconnecter"
export const calculate = "Calculer"
export const login = "Connexion"
export const username = "Nom d'utilisateur"
export const password = "Mot de passe"
export const welcomeMessage1 = "Veuillez vous connecter"
export const welcomeMessage2 = "pour continuer."
export const profile = "Profil"
export const users = "Utilisateurs"
export const profileTitle = "Mettre à jour le profil"
export const usernameLabel = "Nom d'utilisateur"
export const newPasswordLabel = "Nouveau mot de passe"
export const repeatPasswordLabel = "Répéter le mot de passe"
export const firstNameLabel = "Prénom"
export const lastNameLabel = "Nom de famille"
export const emailLabel = "E-mail"
export const newPasswordPlaceholder = "Insérer le mot de passe..."
export const repeatPasswordPlaceholder = "Insérez à nouveau un nouveau mot de passe..."
