import { ChakraProvider } from "@chakra-ui/react"
import axios from "axios"
import { BrowserRouter as Router } from "react-router-dom"
import GlobalStyles from "./components/GlobalStyles"
import { base_url, loginURL } from "./redux/api"
import useUser from "./redux/hooks/useUser"
import store from "./redux/store"
import AppRouter from "./routing/AppRouter"
import { theme } from "./theme"

function App() {
	const hookUser = useUser()

	axios.defaults.baseURL = base_url

	axios.interceptors.request.use(function (config) {
		if (store.getState().user.token) {
			config.headers.Authorization = `Bearer ${store.getState().user.token}`
		}

		return config
	})

	axios.interceptors.response.use(
		(response) => {
			return response
		},
		(error) => {
			if (error.response !== undefined) {
				if (error.response.status === 401) {
					if (error.response.data.code === "token_not_valid") {
						hookUser.signout()
					} else if (error.response.config.url !== loginURL) {
						hookUser.refreshAccess()
					}
				}
			}

			return error
		}
	)

	return (
		<ChakraProvider theme={theme}>
			<div className="App">
				<GlobalStyles />
				<Router>
					<AppRouter />
				</Router>
			</div>
		</ChakraProvider>
	)
}

export default App
