import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

export default function NotFoundPage(props) {
  const history = useHistory();

  return (
    <ContainerStyle>
      <h1>404</h1>
      <h3>Oops... Looks like you got lost.</h3>
      <h3>Click the button bellow so you can return to safety.</h3>
      <BackBtnStyled onClick={history.goBack}>GO BACK</BackBtnStyled>
    </ContainerStyle>
  );
}

const ContainerStyle = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 12px;

  h1 {
    color: var(--red);
    font-size: 80px;
  }

  h3 {
    color: var(--blue);
    font-size: 30px;
  }
`;

const BackBtnStyled = styled.button`
  color: var(--white);
  background: var(--green);
  cursor: pointer;
  margin-bottom: 100px;
`;
