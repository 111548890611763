import React from "react"
import styled from "styled-components"
// import useUser from "../../redux/hooks/useUser"

const TableFrame = ({ children }) => {

    return (
        <ContainerStyles>
            <FrameTableStyles
                style={{
						height: "calc(100vh - 130px)",
						marginTop: "15px",
						maxWidth: "90%",
						width: "100%",
						minWidth: "300px",
				}}
            >
                {children}
            </FrameTableStyles>
        </ContainerStyles>
    )
}
const ContainerStyles = styled.div`
    width: 100%;
    height: 93vh;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
`
const FrameTableStyles = styled.div`
    display: flex;
    direction: column;
    width: 96%;
    min-width: 1070px;
    height: 85vh;
    border: 4px solid var(--blue);
    border-radius: 4px;
    overflow-y: auto;
`
export default TableFrame
