import { createAction, createReducer } from "@reduxjs/toolkit"
import axios from "axios"
import jwtDecode from "jwt-decode"
import { serverErrorMessage, wrongCredentialsMessage } from "../../utils/values"
import { loginUrl, profileUpdateURL, refreshTokenURL } from "../api"

const START_REQUEST = createAction("USER/START_REQUEST")
const LOGIN_SUCCESS = createAction("USER/LOGIN_SUCCESS")
const REQUEST_FAIL = createAction("USER/REQUEST_FAIL")
const REQUEST_SUCCESS = createAction("USER/REQUEST_SUCCESS")
const REFRESH_SUCCESS = createAction("USER/REFRESH_SUCCESS")
const LOGOUT = createAction("USER/LOGOUT")
const CLEAN_MESSAGE = createAction("USER/CLEAN_MESSAGE")

export const loginAuth = () => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.get(loginUrl)
}

export const loginUser = (access, refresh) => async (dispatch) => {
	dispatch(LOGIN_SUCCESS({ access: access, refresh: refresh }))
}

export const logout = () => async (dispatch) => {
	dispatch(LOGOUT())
}

export const refreshAccessToken = (data) => async (dispatch) => {
	const payload = { refresh: data }
	dispatch(START_REQUEST())
	return axios.post(refreshTokenURL, payload).then((res) => handleResponse(res, dispatch, REFRESH_SUCCESS, REQUEST_FAIL))
}

export const updateProfileData = (payload) => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.put(profileUpdateURL, payload).then((res) => handleResponse(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
}

export const cleanMessage = () => async (dispatch) => {
	return dispatch(CLEAN_MESSAGE())
}

const handleResponse = (res, dispatch, success, fail) => {
	if (res !== undefined) {
		if (res.status >= 200 && res.status <= 299) {
			dispatch(success(res.data))
			dispatch(CLEAN_MESSAGE())
			return res
		} else if (res.response !== undefined) {
			if (res.response.status === 401) {
				dispatch(fail(wrongCredentialsMessage))
			} else if (res.response.status >= 400 && res.response.status <= 499) {
				dispatch(fail(res.response.data.message))
			} else {
				dispatch(fail(serverErrorMessage))
			}
			return res.response
		} else {
			dispatch(fail(serverErrorMessage))
		}
	} else {
		dispatch(fail(serverErrorMessage))
	}
}

const initState = {
	token: "",
	refreshToken: "",
	username: "",
	isAdmin: false,
	firstName: "",
	lastName: "",
	email: "",
	loading: false,
	message: null,
}

export const userReducer = createReducer(initState, (builder) => {
	builder
		.addCase(START_REQUEST, (state, action) => {
			state.loading = true
			state.message = null
		})
		.addCase(LOGIN_SUCCESS, (state, action) => {
			const decoded = jwtDecode(action.payload.access)
			state.loading = false
			state.token = action.payload.access
			state.refreshToken = action.payload.refresh
			state.isAdmin = decoded.is_admin
			state.firstName = decoded.firstname
			state.lastName = decoded.lastname
			state.email = decoded.email
			state.username = decoded.username
		})
		.addCase(REFRESH_SUCCESS, (state, action) => {
			const decoded = jwtDecode(action.payload.access)
			state.loading = false
			state.token = action.payload.access
			state.isAdmin = decoded.is_admin
			state.firstName = decoded.firstname
			state.lastName = decoded.lastname
			state.email = decoded.email
			state.username = decoded.username
		})
		.addCase(REQUEST_SUCCESS, (state, action) => {
			state.loading = false
			state.message = action.payload.message
		})
		.addCase(REQUEST_FAIL, (state, action) => {
			state.loading = false
			state.message = action.payload
		})
		.addCase(LOGOUT, (state, action) => {
			state.loading = false
		})
		.addCase(CLEAN_MESSAGE, (state, action) => {
			state.message = null
		})
})
