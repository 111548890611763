export const customStylesSelect = {
    control: (base) => ({
        ...base,
        height: "50px",
        outline: "none",
        border: "none",
        boxShadow: "none",
        backgroundColor: "var(--blue)",

        borderRadius: "4px",
    }),
    placeholder: (base) => ({
        ...base,
        color: "var(--grey)",
    }),
    dropdownIndicator: (base) => ({
        ...base,
        padding: 4,
    }),
    clearIndicator: (base) => ({
        ...base,
        padding: 4,
    }),
    singleValue: (base) => ({
        ...base,
        color: `var(--white)`,
    }),

    valueContainer: (base) => ({
        ...base,
        padding: "0px 6px",
        color: "var(--white)",
    }),
    input: (base) => ({
        ...base,
        margin: 0,
        padding: 0,
    }),
}

export const customTheme = (base) => {
    return {
        ...base,
        colors: {
            ...base.colors,
            // primary25: "var(--blue)",
            primary: "var(--blue)",
        },
    }
}

export const customStylesCheckbox = {
    dropdownButton: (base) => ({
        ...base,
        width: "100%",
        color: "var(--white)",
        background: "var(--blue)",
        fontSize: "16px",
    }),

    control: (base) => ({
        ...base,
        height: 40,
        outline: "none",
        border: 0,
        borderRadius: "4px",
        boxShadow: "none",
        color: "var(--white)",
        background: "var(--blue)",
        margin: "none",
    }),
    menuList: (base) => ({
        ...base,
        color: "var(--white)",
        background: "var(--blue)",
        maxHeight: "300px",
    }),
    option: (base) => ({
        ...base,
        width: "100%",
    }),
    checkbox: (base) => ({
        ...base,
        // width: "6px",
    }),
    valueContainer: (base) => ({
        ...base,
        padding: "0px 6px",
    }),
    input: (base) => ({
        ...base,
        margin: 0,
        padding: 0,
        color: "var(--white)",
    }),
}
