import { Flex, Heading, Stack } from "@chakra-ui/react"
import React, { useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { toast } from "react-toastify"
import styled from "styled-components"
import { ReactComponent as HidePassword } from "../../assets/hide-password.svg"
import { ReactComponent as ShowPassword } from "../../assets/show-password.svg"
import useUser from "../../redux/hooks/useUser"
import {
	emailLabel,
	firstNameLabel,
	lastNameLabel,
	newPasswordLabel,
	newPasswordPlaceholder,
	profileTitle,
	repeatPasswordLabel,
	repeatPasswordPlaceholder,
	usernameLabel,
} from "../../utils/values"

const UpdateProfileForm = () => {
	const { handleSubmit, control, setValue } = useForm()

	const hook = useUser()

	const [passShown, setPassShown] = useState(false)
	const [repeatPassShown, setRepeatPassShown] = useState(false)

	const toggleVisiblity = (shown, setShown) => {
		setShown(shown ? false : true)
	}

	const submit = (data) => {
		if (data.password !== data.repeated_password) {
			toast.error("Passwords do not match")
		} else {
			const payload = {}
			payload["username"] = hook.username
			if (data.password) {
				payload["password"] = data.password
				payload["repeated_password"] = data.repeated_password
			}

			if (data.first_name) {
				payload["first_name"] = data.first_name
			}

			if (data.last_name) {
				payload["last_name"] = data.last_name
			}

			if (data.email) {
				payload["email"] = data.email
			}

			hook.updateProfile(payload).then((res) => {
				if (res !== undefined && res.status === 200) {
					hook.refreshAccess(hook.refreshToken)
					setValue("password", "")
					setValue("repeated_password", "")
				}
			})
		}
	}

	return (
		<FormWrapper>
			<form onSubmit={handleSubmit(submit)}>
				<Stack
					w="100%"
					maxW="1300px"
					mx="auto"
					align="center"
					spacing={[4, 6, 10]}
					pb={{ base: "40px", md: 0 }}
					pt={{ base: "10px", xl: "150px" }}
				>
					<Heading as="h2" fontWeight={400} fontSize={["24px", "36px"]} mb={["10px", "20px", "50px"]}>
						{profileTitle}
					</Heading>
					<Flex w="100%" gap={[2, 2, 5]} px={[2, 5]} align="center" direction={["column", "column", "row"]}>
						<Stack w="100%" spacing={2}>
							<Stack spacing={"2px"}>
								<label>{usernameLabel}</label>
								<Controller
									render={({ field: { onChange, onBlur, value, ref } }) => (
										<input ref={ref} value={value} onChange={onChange} type="text" />
									)}
									control={control}
									name="username"
									defaultValue={hook.username}
									rules={{ required: true }}
								/>
							</Stack>
							<Stack spacing={"2px"}>
								<label>{newPasswordLabel}</label>
								<div className="pas-input-wrapper">
									<Controller
										render={({ field: { onChange, onBlur, value, ref } }) => (
											<input
												placeholder={newPasswordPlaceholder}
												ref={ref}
												value={value}
												onChange={onChange}
												type={passShown ? "text" : "password"}
											/>
										)}
										control={control}
										name="password"
										defaultValue={""}
									/>
									{passShown ? (
										<HidePassword
											className="visibility-icon"
											onClick={() => toggleVisiblity(passShown, setPassShown)}
										/>
									) : (
										<ShowPassword
											className="visibility-icon"
											onClick={() => toggleVisiblity(passShown, setPassShown)}
										/>
									)}
								</div>
							</Stack>
							<Stack spacing={"2px"}>
								<label>{repeatPasswordLabel}</label>{" "}
								<div className="pas-input-wrapper">
									<Controller
										render={({ field: { onChange, onBlur, value, ref } }) => (
											<input
												placeholder={repeatPasswordPlaceholder}
												ref={ref}
												value={value}
												onChange={onChange}
												type={repeatPassShown ? "text" : "password"}
											/>
										)}
										control={control}
										name="repeated_password"
										defaultValue={""}
									/>
									{repeatPassShown ? (
										<HidePassword
											className="visibility-icon"
											onClick={() => toggleVisiblity(repeatPassShown, setRepeatPassShown)}
										/>
									) : (
										<ShowPassword
											className="visibility-icon"
											onClick={() => toggleVisiblity(repeatPassShown, setRepeatPassShown)}
										/>
									)}
								</div>
							</Stack>
						</Stack>
						<Stack w="100%" spacing={2}>
							<Stack spacing={"2px"}>
								<label>{firstNameLabel}</label>
								<Controller
									render={({ field: { onChange, onBlur, value, ref } }) => (
										<input ref={ref} value={value} onChange={onChange} type="text" />
									)}
									control={control}
									name="first_name"
									defaultValue={hook.firstName}
								/>
							</Stack>
							<Stack spacing={"2px"}>
								<label>{lastNameLabel}</label>
								<Controller
									render={({ field: { onChange, onBlur, value, ref } }) => (
										<input ref={ref} value={value} onChange={onChange} type="text" />
									)}
									control={control}
									name="last_name"
									defaultValue={hook.lastName}
								/>
							</Stack>
							<Stack spacing={"2px"}>
								<label>{emailLabel}</label>
								<Controller
									render={({ field: { onChange, onBlur, value, ref } }) => (
										<input ref={ref} value={value} onChange={onChange} type="text" />
									)}
									control={control}
									name="email"
									defaultValue={hook.email}
								/>
							</Stack>
						</Stack>
					</Flex>
					<button type="submit">Sauver</button>
				</Stack>
			</form>
		</FormWrapper>
	)
}

const FormWrapper = styled.div`
	width: 100%;
	/* text-align: center; */
	/* margin-top: 150px; */
	.pas-input-wrapper {
		position: relative;
	}
	.pas-input-wrapper input {
		padding-right: 50px;
	}

	.visibility-icon {
		position: absolute;
		top: 20%;
		right: 4%;
		path {
			fill: var(--white);
		}
	}
	.title {
		margin-bottom: 50px;
		font-size: 36px;
	}

	label {
		/* font-weight: bold;
		padding-bottom: 5px; */
	}

	input {
		height: 50px;
		padding-left: 8px;
		width: 100%;
		color: var(--white);
		background: var(--blue);
		::placeholder {
			color: var(--white);
		}
	}

	button {
		/* display: block; */
		/* margin: 10px 0 0 auto; */
		background-color: var(--green);
		color: var(--white);
		font-size: 15px;
	}
	@media (max-width: 678px) {
		margin-bottom: 0px;
		.title {
			font-size: 24px;
		}
	}
`

// const Box = styled.div`
// 	display: flex;
// 	width: 100%;
// 	justify-content: center;
// 	column-gap: 30px;
// 	.column-section {
// 		width: 100%;
// 		display: flex;
// 		flex-direction: column;
// 	}
// 	@media (max-width: 678px) {
// 		flex-direction: column;
// 		.title {
// 			font-size: 24px;
// 		}
// 	}
// `

export default UpdateProfileForm
