import React, { useEffect } from "react"
import { useHistory } from "react-router-dom"
import CtForm from "../components/forms/CtForm"
import Navigation from "../components/Navigation"
import useUser from "../redux/hooks/useUser"
import { Container } from "./admin/UsersPage"

const CtPage = () => {
	const hook = useUser()

	const history = useHistory()

	const access = new URLSearchParams(window.location.search).get("access")
	const refresh = new URLSearchParams(window.location.search).get("refresh")

	useEffect(() => {
		if (access && refresh) {
			hook.signin(access, refresh)
		} else if (!hook.token) {
			history.push("/login")
		}
	}, [])

	return (
		<Container>
			<Navigation />
			<CtForm />
		</Container>
	)
}

export default CtPage
