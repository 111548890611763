import { useDispatch, useSelector } from "react-redux";
import { calculateCtPrediction, cleanMessageAndGraph, explainCtPrediction } from "../reducers/ctReducer";

export default function useCt() {

	const dispatch = useDispatch();
	const graphData = useSelector((state) => state.ct.graphData);
	const message = useSelector((state) => state.ct.message);
	const loading = useSelector((state) => state.ct.loading);

	const explainCt = async (payload) => {
		return dispatch(explainCtPrediction(payload));
	};

	const predictCt = async (payload) => {
		return dispatch(calculateCtPrediction(payload));
	};

	const clean = async () => {
		return dispatch(cleanMessageAndGraph())
	}

	return {
		graphData,
		message,
		loading,
		explainCt,
		predictCt,
		clean,
	};
}