import { createAction, createReducer } from "@reduxjs/toolkit"
import axios from "axios";
import { serverErrorMessage } from "../../utils/values";
import { explainRbURL, predictRbURL } from "../api";

const START_REQUEST = createAction("RB/START_REQUEST")
const FETCH_SUCCESS = createAction("RB/FETCH_SUCCESS")
const REQUEST_SUCCESS = createAction("RB/REQUEST_SUCCESS")
const REQUEST_FAIL = createAction("RB/REQUEST_FAIL")
const CLEAN_MESSAGE_AND_GRAPH = createAction("RB/CLEAN_MESSAGE_AND_GRAPH")


export const explainRbPrediction = (payload) => async (dispatch) => {  
	dispatch(START_REQUEST())
	return axios.post(explainRbURL, payload)
	.then(res => handleResponse(res, dispatch, FETCH_SUCCESS, REQUEST_FAIL))
};

export const calculateRbPrediction = (payload) => async (dispatch) => {  
	dispatch(START_REQUEST())
	return axios.post(predictRbURL, payload)
	.then(res => handleResponse(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
};

export const cleanMessageAndGraph = () => async (dispatch) => {
    return dispatch(CLEAN_MESSAGE_AND_GRAPH())
}

const handleResponse = (res, dispatch, success, fail) => {
	if(res !== undefined) {
		if(res.status >= 200 && res.status <= 299) {
			dispatch(success(res.data))
			return res
		}else if(res.response !== undefined && res.response.status >= 400 && res.response.status <= 499){
			dispatch(fail(res.response.data.message))
		}else{
			dispatch(fail({text: serverErrorMessage, type: "error"}))
			dispatch(CLEAN_MESSAGE_AND_GRAPH())
		}
	}else{
		dispatch(fail({text: serverErrorMessage, type: "error"}))
		dispatch(CLEAN_MESSAGE_AND_GRAPH())
	}
}

const initState = {
	graphData: null,
	message: null,
	loading: false,
};

export const rbReducer = createReducer(initState, (builder) => {
	builder
		.addCase(START_REQUEST, (state, action) => {
			state.loading = true
			state.message = null
		})
		.addCase(FETCH_SUCCESS, (state, action) => {
			state.loading = false
			state.graphData = action.payload
		})
		.addCase(REQUEST_SUCCESS, (state, action) => {
			state.loading = false
			state.message = action.payload
		})
		.addCase(REQUEST_FAIL, (state, action) => {
			state.loading = false
			state.message = action.payload
		})
		.addCase(CLEAN_MESSAGE_AND_GRAPH, (state, action) => {
			state.message = null
			state.graphData = null
		})
	})