import React, { useEffect } from "react"
import useUser from "../redux/hooks/useUser"
const LoginPage = () => {
	const userHook = useUser()

	useEffect(() => {
		userHook.login().then((res) => {
			if (res !== undefined && res.status === 200) {
				window.location.replace(res.data.uri)
			}
		})
	}, [])

	return (
		// <ContainerStyles>
		//   <TitleSectionStyles>
		//     <div className="titleContainer">
		//       <h1>
		//         {welcomeMessage1}
		//         <br /> {welcomeMessage2}
		//       </h1>
		//       <div className="underLine"></div>
		//     </div>
		//   </TitleSectionStyles>
		//   <LoginSectionStyles>
		//     <img src={logo} alt="Logo" />
		//     <h2>{appName}</h2>
		//     <LoginForm />
		//   </LoginSectionStyles>
		// </ContainerStyles>
		<></>
	)
}

// const ContainerStyles = styled.div`
// 	width: 100%;
// 	height: 100vh;
// 	display: flex;
// `
// const TitleSectionStyles = styled.div`
// 	width: 50%;
// 	background: var(--white);
// 	display: flex;
// 	flex-direction: column;
// 	justify-content: center;
// 	align-items: center;
// 	row-gap: 20px;
// 	.titleContainer {
// 		width: 100%;
// 		height: 320px;
// 		padding-left: 87px;
// 	}
// 	h1 {
// 		font-size: clamp(50px, 5vw, 75px);
// 		color: var(--blue);
// 		margin-bottom: 26px;
// 		font-weight: 700;
// 	}
// 	.underLine {
// 		width: 100%;
// 		height: 6px;
// 		background: var(--blue);
// 	}
// `
// const LoginSectionStyles = styled.div`
// 	display: flex;
// 	flex-direction: column;
// 	width: 50%;
// 	background: var(--blue);
// 	justify-content: center;
// 	align-items: center;
// 	img,
// 	h2 {
// 		padding-bottom: 20px;
// 	}
// 	h2 {
// 		font-size: 25px;
// 		color: var(--white);
// 		font-weight: 500;
// 	}
// `

export default LoginPage
