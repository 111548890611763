import UsersPage from "../pages/admin/UsersPage"
import CtPage from "../pages/CtPage"
import LoginPage from "../pages/LoginPage"
import NotFoundPage from "../pages/NotFoundPage"
import ProfilePage from "../pages/ProfilePage"
import ReimbursementPage from "../pages/ReimbursementPage"

const routes = [
	{
		path: "/",
		component: CtPage,
		isPrivate: false,
	},
	{
		path: "/ct",
		component: CtPage,
		isPrivate: true,
	},
	{
		path: "/reimbursement",
		component: ReimbursementPage,
		isPrivate: true,
	},
	{
		path: "/login",
		component: LoginPage,
	},
	{
		path: "/admin/users",
		component: UsersPage,
		isAdmin: true,
		isPrivate: true,
	},
	{
		path: "/profile",
		component: ProfilePage,
		isPrivate: true,
	},
	{
		path: "/*",
		component: NotFoundPage,
		isPrivate: true,
	},
]

export default routes
