import { createAction, createReducer } from "@reduxjs/toolkit"
import axios from "axios"
import { usersPageURL, usersURL } from "../api"


const START_REQUEST = createAction("USERS/START_REQUEST")
const FETCH_SUCCESS = createAction("USERS/FETCH_SUCCESS")
const REQUEST_FAIL = createAction("USERS/REQUEST_FAIL")
const REQUEST_SUCCESS = createAction("USERSS/REQUEST_SUCCESS")
const CLEAN_MESSAGE = createAction("USERS/CLEAN_MESSAGE")

export const fetchUsers = (pageNumber, pageSize) => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.get(usersPageURL(pageNumber, pageSize))
	.then(res => handleSuccess(res, dispatch, FETCH_SUCCESS))
	.catch(function (error) {
		handleError(error, dispatch, REQUEST_FAIL)
	})
}

export const createUser = (data) => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.post(usersURL, data)
	.then(res => handleSuccess(res, dispatch, REQUEST_SUCCESS))
	.catch(function (error) {
		handleError(error, dispatch, REQUEST_FAIL)
	})
}

export const updateUser = (data) => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.put(usersURL, data)
	.then(res => handleSuccess(res, dispatch, REQUEST_SUCCESS))
	.catch(function (error) {
		handleError(error, dispatch, REQUEST_FAIL)
	})
}

export const cleanMessage = () => async (dispatch) => {
    return dispatch(CLEAN_MESSAGE())
}

const handleSuccess = (res, dispatch, success) => {
	if(res !== undefined) {

		dispatch(success(res.data))
				
		return res
	}
}

const handleError = (error, dispatch, fail) => {
	if (error.response) {
		if (error.response.status === 500) {
			dispatch(fail({ type: "error", text: "Serverski problem!"}))
		} else if(error.response.status === 400) {
			dispatch(fail(error.response.data.message))
		} else {
			dispatch(fail({ type: "error", text: "Serverski problem!"}))
		}
	} else {
		dispatch(fail({ type: "error", text: "Serverski problem!"}))
	}

	dispatch(CLEAN_MESSAGE())
	return error

}


const initState = {
    data: [],
	pagination: null,
    loading: false,
    message: null,
}

export const usersReducer = createReducer(initState, (builder) => {
    builder
        .addCase(START_REQUEST, (state, action) => {
            state.loading = true
            state.message = null
        })
        .addCase(REQUEST_FAIL, (state, action) => {
            state.loading = false
            state.message = action.payload
        })
        .addCase(FETCH_SUCCESS, (state, action) => {
			state.loading = false
            state.data = action.payload.dataset
			state.pagination = action.payload.pagination
        })
		.addCase(REQUEST_SUCCESS, (state, action) => {
			state.loading = false
            state.message = action.payload.message
        })
        .addCase(CLEAN_MESSAGE, (state, action) => {
            state.message = null
        })
})