export const base_url = process.env.REACT_APP_API_URL

export const loginURL = "/user/login/"
export const loginUrl = "/auth/login/"

export const predictCtURL = "/predict_hta"
export const predictRbURL = "/predict_rb"
export const explainCtURL = "/explain_hta"
export const explainRbURL = "/explain_rb"
export const usersURL = "/users"

export const usersPageURL = (pageNumber, perPage) => `${usersURL}?per_page=${perPage}&page_no=${pageNumber}&order_by=id`

export const defaultPageSize = 15

export const refreshTokenURL = "/user/refresh/"
export const profileUpdateURL = "/user/profile"

// api/auth/login/
