import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import styled from "styled-components"

import {
	asmrOptions,
	asmrOptions2,
	asmrsOptionsDisabled,
	bmOptions,
	classOptions,
	generateRbMessage,
	handleValue,
	payloadReimbursement,
	phiOptions,
	rbOptions,
	smsrOptions,
	superAireOptions,
	typeDemandeOptions,
	yesNoOptions,
	yesNoOptionsDisabled,
} from "../../utils/dropdownData"

import { Flex, Stack } from "@chakra-ui/react"
import useRb from "../../redux/hooks/useRb"
import {
	asmrLabel,
	asmrObtenuLabel,
	atLabel,
	atuLabel,
	avisLabel,
	bmLabel,
	calculate,
	classLabel,
	coll,
	cpLabel,
	dateAmmLabel,
	dateAvisLabel,
	defaultDate,
	five,
	formHeaderRb,
	four,
	ispsLabel,
	negoListConditionMessage,
	negoPrixLabel,
	no,
	one,
	orphelinLabel,
	phiLabel,
	rbListLabel,
	smrsLabel,
	ss,
	superAireLabel,
	three,
	typeDemandeLabel,
	yes,
} from "../../utils/values"
import Chart from "../Chart"
import { LabelDate, LabelSelect, LabelSelectNoController } from "../SelectDropdown"

const ReimbursementForm = () => {
	const hook = useRb()

	const { handleSubmit, control, watch } = useForm()

	const [firstDate, setFirstDate] = useState(defaultDate)
	const [secondDate, setSecondDate] = useState(defaultDate)
	const [avisCeespValue, setAvisCeespValue] = useState(no)
	const [asmrsValue, setAsmrsValue] = useState(three)
	const [asmrs2Value, setAsmrs2Value] = useState(one)
	const [message, setMessage] = useState(null)
	const [graphData, setGraphData] = useState(null)
	const [negoPrixValue, setNegoPrixValue] = useState(yes)
	const [rbValue, setRbValue] = useState(ss)

	const rbCondition = [four, five]

	useEffect(() => {
		hook.clean()
	}, []) //eslint-disable-line

	useEffect(() => {
		if (hook.graphData) {
			setGraphData(hook.graphData)
		} else {
			setGraphData(null)
		}
	}, [hook.graphData])

	const handleReset = () => {
		setGraphData(null)
		setMessage(null)
	}

	useEffect(() => {
		const subscription = watch(() => handleReset())
		return () => subscription.unsubscribe()
	}, [watch])

	useEffect(() => {
		if (typeof hook.message === "number") {
			setMessage(hook.message)
		} else if (hook.message) {
			setMessage(hook.message)
		} else {
			setMessage(null)
		}
	}, [hook.message])

	useEffect(() => {
		if (negoPrixValue === no && rbValue !== coll) {
			setMessage(negoListConditionMessage)
		} else if (message === negoListConditionMessage) {
			setMessage(null)
		}
	}, [negoPrixValue, rbValue]) //eslint-disable-line

	const submit = (data) => {
		hook.clean()
		setMessage(null)
		setGraphData(null)

		data["date_amm"] = firstDate
		data["date_avis"] = secondDate
		data["avis_ceesp"] = avisCeespValue
		data["asmrs"] = asmrsValue
		data["asmrs_obtenu"] = asmrs2Value
		data["nego_prix"] = negoPrixValue
		data["rb_list"] = rbValue

		hook.explainRb(payloadReimbursement(data))
		hook.predictRb(payloadReimbursement(data))
	}

	const handleDate = (data, setValue) => {
		setGraphData(null)
		setValue(data)
	}

	return (
		<>
			<Form onSubmit={handleSubmit(submit)}>
				<h2 className="title">{formHeaderRb}</h2>
				<Flex
					gap={[2, 2, 5]}
					w="100%"
					maxW="1400px"
					px={5}
					direction={["column", "column", "row"]}
					sx={{
						".react-datepicker__input-container input": { pl: 2 },
					}}
				>
					<Stack w="100%" spacing={4}>
						<LabelDate label={dateAmmLabel} value={firstDate} setValue={setFirstDate} handleChange={handleDate} />

						<LabelSelectNoController
							label={rbListLabel}
							options={rbOptions}
							defaultValue={rbOptions[0]}
							onChange={(val) => handleValue(val, setRbValue)}
						/>

						<LabelSelect
							regName="type_demande"
							label={typeDemandeLabel}
							options={typeDemandeOptions}
							defaultValue={typeDemandeOptions[0]}
							control={control}
						/>

						<LabelSelect
							regName="clas"
							label={classLabel}
							options={classOptions}
							defaultValue={classOptions[2]}
							control={control}
						/>

						<LabelSelect regName="phi" label={phiLabel} options={phiOptions} defaultValue={phiOptions[0]} control={control} />

						<LabelSelect
							regName="smrs"
							label={smrsLabel}
							options={smsrOptions}
							defaultValue={smsrOptions[0]}
							control={control}
						/>

						<LabelSelectNoController
							label={asmrLabel}
							options={avisCeespValue === yes ? asmrsOptionsDisabled : asmrOptions}
							defaultValue={asmrOptions[2]}
							onChange={(val) => handleValue(val, setAsmrsValue)}
						/>

						<LabelSelectNoController
							label={asmrObtenuLabel}
							options={asmrOptions2}
							defaultValue={asmrOptions2[0]}
							onChange={(val) => handleValue(val, setAsmrs2Value)}
						/>

						<LabelSelect
							regName="super_aire"
							label={superAireLabel}
							options={superAireOptions}
							defaultValue={superAireOptions[1]}
							control={control}
						/>
					</Stack>
					<Stack w="100%" spacing={4}>
						<LabelDate label={dateAvisLabel} value={secondDate} setValue={setSecondDate} handleChange={handleDate} />

						<LabelSelectNoController
							label={negoPrixLabel}
							options={yesNoOptions}
							defaultValue={yesNoOptions[0]}
							onChange={(val) => handleValue(val, setNegoPrixValue)}
						/>

						<LabelSelect
							regName="atu"
							label={atuLabel}
							options={yesNoOptions}
							defaultValue={yesNoOptions[0]}
							control={control}
						/>

						<LabelSelect
							regName="orphelin"
							label={orphelinLabel}
							options={yesNoOptions}
							defaultValue={yesNoOptions[1]}
							control={control}
						/>

						<LabelSelect regName="cp" label={cpLabel} options={yesNoOptions} defaultValue={yesNoOptions[1]} control={control} />

						<LabelSelectNoController
							label={avisLabel}
							options={rbCondition.includes(asmrs2Value) ? yesNoOptionsDisabled : yesNoOptions}
							defaultValue={yesNoOptions[1]}
							onChange={(val) => handleValue(val, setAvisCeespValue)}
						/>

						<LabelSelect
							regName="isps"
							label={ispsLabel}
							options={yesNoOptions}
							defaultValue={yesNoOptions[0]}
							control={control}
						/>

						<LabelSelect regName="bm" label={bmLabel} options={bmOptions} defaultValue={bmOptions[0]} control={control} />

						<LabelSelect regName="at" label={atLabel} options={yesNoOptions} defaultValue={yesNoOptions[0]} control={control} />
					</Stack>
				</Flex>
				<CalculateButton type="submit">{calculate}</CalculateButton>
				{message && generateRbMessage(message)}
				{graphData && <h3 className="title">{"Impact des différents paramètres sur la prédiction effectuée:"}</h3>}
				{graphData && (
					<div className="chart-container">
						<Chart data={graphData} />
					</div>
				)}
			</Form>
		</>
	)
}

const Form = styled.form`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	/* height: calc(100vh - 160px); */
	.title {
		margin: 40px 10px;
		font-weight: 700;
	}
	/* .container {
		width: 80%;
		display: flex;
		grid-gap: 20px;
		margin: 0 10px;
	} */
	/* .left-side,
	.right-side {
		width: 50%;
		display: flex;
		flex-direction: column;
		row-gap: 20px;
	} */
	/* @media (max-width: 1200px) {
		.container {
			width: 90%;
		}
	}
	@media (max-width: 768px) {
		.container {
			flex-direction: column;
			align-items: center;
		}
		.left-side,
		.right-side {
			width: 90%;
		}
	} */

	.chart-container {
		width: 80%;
		margin-bottom: 100px;
	}
`
const CalculateButton = styled.button`
	background: var(--green);
	color: var(--white);
	font-size: 18px;
	margin: 30px 0;
`

export default ReimbursementForm
